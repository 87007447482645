










import { Component, Vue } from 'vue-property-decorator';
import UserHeader from './Header.vue';
import UserView from './View.vue';
import Footer from '../common/Footer.vue';

@Component({
  components: {
    UserHeader,
    UserView,
    Footer,
  },
})
export default class UserIndex extends Vue {
}
