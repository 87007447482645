

































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { User, HazeruArtNotification } from '@/repository/artsub-backend-types';
import {
  getPaymentIntent, getNotifications, postNotificationsRead,
} from '@/repository/artsub-backend';
import { mapGetters } from 'vuex';

@Component({
  computed: mapGetters(['user', 'jwtToken', 'isAuthenticatedUser', 'isArtistUser']),
})
export default class UserHeader extends Vue {
  user!: User | null;

  jwtToken!: string | null;

  isAuthenticatedUser!: boolean;

  isArtistUser!: boolean;

  drawer = false;

  searchQuery = '';

  showCartBadge = false;

  notifications: Array<HazeruArtNotification> = [];

  showNotifications = false;

  cartCount = 0;

  selectedSearchMode = 'アート';

  searchModes = ['アート', 'アーティスト']

  @Watch('showNotifications')
  async showNotificationsChanged(): Promise<void> {
    if (this.isAuthenticatedUser && this.jwtToken !== null) {
      await postNotificationsRead(this.jwtToken);
      await this.getNotifications();
    }
  }

  async created(): Promise<void> {
    if (this.isAuthenticatedUser) {
      await Promise.all([this.getNotifications(), this.getCartCount()]);
    } else {
      this.subscribeSetUser();
    }
  }

  async updated(): Promise<void> {
    if (this.isAuthenticatedUser) {
      await this.getCartCount();
    }
  }

  subscribeSetUser(): void {
    this.$store.subscribe(async (mutation, state) => {
      if (mutation.type === 'setUser' && state.user !== null) {
        await Promise.all([this.getNotifications(), this.getCartCount()]);
      }
    });
  }

  async getNotifications(): Promise<void> {
    if (this.jwtToken === null) return;
    const res = await getNotifications(this.jwtToken);
    if (res.status === 200) {
      this.notifications = res.data.results;
    }
  }

  async getCartCount(): Promise<void> {
    if (this.jwtToken === null) return;
    const res = await getPaymentIntent(this.jwtToken).catch(() => console.log('no paymentintent'));
    if (!res) return;

    let count = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const [_, amount] of Object.entries(res.data.metadata)) {
      if (amount !== '0') {
        count += 1;
      }
    }
    this.cartCount = count;
    console.log('cart count=');
    console.log(this.cartCount);
    this.showCartBadge = false;
  }

  get countUnread(): number {
    return this.notifications.filter((n) => !n.read).length;
  }

  get hiddenDrawer(): boolean {
    return ['/user/terms-of-service', '/auth'].includes(this.$route.path);
  }

  get artistSigningUpAvailable(): boolean {
    if (!this.isAuthenticatedUser) {
      return false;
    }

    // artist_approval_requestがnullの場合は申請前のため申請可能状態となる
    return this.user !== null && this.user.artist_approval_request === null;
  }

  search(keyCode: number): void {
    if (this.searchQuery !== '') {
      if (this.selectedSearchMode === 'アート') {
        this.$router.push({ path: '/user/art', query: { query: this.searchQuery } });
      } else if (this.selectedSearchMode === 'アーティスト') {
        this.$router.push({ path: '/user/artist', query: { query: this.searchQuery } });
      }
    }
  }

  toNextPathOrSignInPath(path: string): string {
    if (this.isAuthenticatedUser) {
      return path;
    }

    return `/auth?redirect=${path}`;
  }

  async toUserHome(): Promise<void> {
    await this.$router.push('/user/home');
    await this.$router.go(0);
  }

  get isMobile(): boolean {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return true;
      case 'sm': return true;
      case 'md': return false;
      case 'lg': return false;
      case 'xl': return false;
      default: return false;
    }
  }
}
